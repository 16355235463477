<template>
  <layout-horizontal-main>
    <router-view />
  </layout-horizontal-main>
</template>

<script>
import LayoutHorizontalMain from '@/layouts/horizontal/LayoutHorizontalMain.vue'

export default {
  name: 'Main',
  components: {
    LayoutHorizontalMain,
  },
}
</script>

<style scoped>

</style>
